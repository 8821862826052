import React, { useContext } from "react"
import Text from '@typo/Text'
import StyledLink from '@typo/StyledLink'
import MainHeading from '@typo/MainHeading'
import styled from 'styled-components'
import headerImage from '@images/articles/erotic-audio-stories/blog-header.svg'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import Seo from "@components/Seo";
import FAQContent from '@components/schemas/seoEroticAudiobookStories.json'
import Header from "@menus/header"
import { FirebaseContext } from '@components/Firebase'

const ArticleWrapper = styled.div`
  display:flex;
  justify-content: center;
  padding-top:90px;
  @media (min-width: 768px) {
    width:100%;
    padding-top:150px;
  }
`

const ArticleHeaderImage =  styled.img`
/* padding-top:30px; */
`

const ContentPaddingWrapper = styled.div`
  max-width:900px;
  padding: 0px 5% 100px 5%;
`

const FirstText =  styled(Text)`
 &:first-letter {
    text-transform:${props => props.title ? '' : 'uppercase'};
    font-size:${props => props.title  ? '' : '200%'};
    font-family:${props => props.title ? '' : props.theme.mainFontHeading};
    line-height: 1;
  }
  margin-bottom:50px;

`

const MetaData =  styled.p`
  font-family:${props => props.title ? '' : props.theme.mainFontParagraph};
  font-size:0.8rem;
  line-height:0.5;
`
const ListItemWrapper = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  padding-bottom:50px;
  align-items: center;

`

const ItemImageWrapper = styled.div`
  width:100%;
  @media (min-width: 768px) {
    width:100%;
  }
`

const ItemTextColumn = styled.div`

  
`
const ItemText = styled.div`
  font-family: ${props => props.theme.mainFontParagraph},'Arial';
  line-height: 1.6;
  h4 {
    margin: 0px;
    font-size: 1.4rem;
  }

  a {
    color: ${props => props.theme.secondaryColor};
  }
`

const ItemCta = styled(Text)`
  font-family:'Poppins';
`
const ItemMetaInfo = styled(Text)`
  line-height:1;
`
const SubHeadline = styled(Text)`
  padding-bottom:10px;
  font-style:italic;
`

const EroticAudio = styled.span`
  font-size:14px; 
  font-family:'Poppins';
  padding-left:3px;
  display: block;
`

const ItemHeadline = styled.h2`
  font-size: 2.5rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`
const FAQItemHeadline = styled.h3`
  font-size: 2rem;
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
 
  }
`

const HeaderTitle = styled.h2`
  ${props => props.topPadding ? 'padding-top: 100px;' : ''}
  font-size: 3rem;
  ${props => props.center ? 'text-align:center;' : ''}
  @media ${props => props.theme.phoneLand} {
    font-size: 2rem;
    text-align:center;
  }
`

const content = {
  "seduced-by-paris":{
    "subtitle":"Meditiation Erotic Audio:",
    "text":"This romantic and sensual audio story details a morning in bed, reading a sexy love letter from your partner after he has already left. He confesses his love and recounts all the exciting and erotic things you did last night, in every naughty little detail. This 9-minute quickie romance is sure to get you and your lover in the mood for some sensual bonding time.",
    "rank":10
  },
  "mountaintop-adventure":{
    "subtitle":"Public Sex Erotic Audio:",
    "text":"In this audio sex story, you and your lover enjoy a nice hike in nature. Once you come to the top of the mountain, you smile in awe at the beautiful landscape before you. It isn’t long before you’re both exploring each other’s bodies in the intimate privacy of nature. Enjoy a public sex adventure in this 22 minute erotic audiobook.",
    "rank":9
  },
  "edge-of-silk":{
    "subtitle":"Meditiation Erotic Audio:",
    "text":"During this erotic audio story, you will be guided through a sensual self-care session to help you revitalize and relax. It’s like daydreaming...all you need to do is close your eyes and let the sound of his voice wash over you. Join erotic audio artist Devlin Wylde in this sensual 52 minute meditation audiobook. ",
    "rank":8
  },
  "wedding-night":{
    "subtitle":"Romantic Erotic Audio:",
    "text":"After so much planning, the night is finally here...you’re getting married. In this romantic audio sex story, you and your newly-wed husband cannot keep your hands off each other at the reception. As such, you decide to sneak away for some frisky fun while your guests are dancing. Relive magical moments in this 21 minute wedding night audiobook.",
    "rank":7
  },
  "sensuous-valentine":{
    "subtitle":"Meditiation Erotic Audio:",
    "text":"Erotic audio artist Devlin is back with a sensual self care message for you: you deserve relaxation and pleasure. Explore a romantic, rose-scented evening while imagining the gentle touch of a lover giving you a deeply relaxing massage. Unwind and let your troubles melt away with this 33 minute meditation audiobook.",
    "rank":6, 
  },
  "stranger-in-streets":{
    "subtitle":"Stranger Erotic Audio:",
    "text":"Breakups are hard - we get it. In this audio sex story, you’re on a short, spur-of-the-moment vacation in Rome after ending a long-term relationship. The neon lights create shadows in the cobbled-stone streets and you find yourself being able to really breathe for the first time in months. You want to collect your broken pieces and glue them back together...and the handsome waiter from the local restaurant may be able to help with that. Enjoy a steamy foreigner fling in this 20 minute erotic audiobook.",
    "rank":5
  },
  "alone-in-the-office":{
    "subtitle":"Sex At Work Erotic Audio:",
    "text":"Who says work has to be boring? In this erotic audio story, you and your secret lover are the only ones left still in the office while everyone is away at an important meeting. You can’t stop flirting with each other - you both find it so hard to keep things under wraps while at work. Enjoy diving into this secret office romance in this 21 minute erotic audiobook.",
    "rank":4
  },
  "muse":{
    "subtitle":"Friends To Lovers Erotic Audio:",
    "text":"You have an appointment to discuss your latest assignment with your lecturer. During class, you can’t help but admire her - her body, her voice, the way she commands the room. You notice her gaze settling on you and hope she’s thinking about the same things you are. Your appointment proves to be equally pleasurable for you both as you get lost in each other. This 9-minute lesbian Erotic Audio is everyone’s naughtiest teacher-student fantasy come true.",
    "rank":3
  },
  "ranch-hand":{
    "subtitle":"In The Car Erotic Audio:",
    "text":"You’re away at a business conference and are finally back in your hotel room, ready to unwind for the evening. You decide to call your husband and see how he’s doing at the house without you, and things quickly turn hot and steamy when he says he may have to touch himself while you talk, since he’s missed you so much. You’re intrigued, and play along, letting the intimate conversation lead as your hands explore your own body to the sound of his voice. This 8-minute Erotic Audio is great for couples who want to stay connected.",
    "rank":2
  },
  "nightclub-heat":{
    "subtitle":"In Public Erotic Audio:",
    "text":"This erotic audiobook features a stranger sex story that really brings the heat. You’re at the club with some friends when you notice a stranger making eye contact with you. She seems totally hypnotized by everything you’re doing. Soon, the attractive woman approaches you and things quickly heat up on the dance floor. It’s not long before you’re getting lost in each other in the ladies’ restroom. Listen to things get wild with a sexy stranger in this 20-minute LGBTQ+ audiobook story.",
    "rank":1
  },

}


const articleSchema = {
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "headline": "Enjoy sensual relaxation with erotic audiobook stories.",
  "image": [
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audiobook-stories-audiodesires-1-1.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audiobook-stories-audiodesires-4-3.jpg",
    "https://audiodesires.com/images/erotic-audio-stories/top-10-audiobook-stories-audiodesires-16-9.jpg"
   ],
  "datePublished": "2021-26-03",
  "dateModified": "2021-26-03",
  "isAccessibleForFree":true,
  "author": {
    "@type": "Person",
    "name": "Jaimee Bell"
  },
   "publisher": { 
    "@type": "Organization",
    "name": "Audiodesires",
    "logo": {
      "@type": "ImageObject",
      "url": "https://audiodesires.com/images/logos/audiodesires_black.png"
    }
  },
  "mainEntityOfPage":{
    "@type":"WebPage",
    "@id":"https://audiodesires.com/erotic-audiobook-stories/"
 }
}

const EroticAudioBookStories = (props) => {
  const {user} = useContext(FirebaseContext);
  const lang = props.pageContext.lang

  const convertArrayToObject = (array, key, content) => {
    const initialValue = [];
    return array.reduce((obj, item) => {
      return [...obj,{...content[item.en[key]],...item}]
    }, initialValue);
  };
  const Stories =  convertArrayToObject(props.data.allStory.nodes, 'slug', content)

  return <>
  <Header boxLayout={false} playerMode={'small'} user={user} alwaysShow={true}/>
  <ArticleWrapper>
    <Seo 
      title={"Enjoy sensual relaxation with erotic audiobook stories"}
      description={"Erotic audiobooks stories for women ✅ Professional audio erotica ✅ Different stories and fantasies ✅ 5-20 min ✅ Free stories available ✅ Respectful & relatable"}
      structuredData={[articleSchema, FAQContent]}
    />
    <ContentPaddingWrapper>
      
      <MainHeading>{articleSchema.headline}</MainHeading>
      <SubHeadline>Enjoy sensual relaxation with erotic audiobook stories</SubHeadline>
      <MetaData>Last Updated on March 26, 2021 </MetaData>
      <MetaData>by {articleSchema.author.name} </MetaData>
      <ArticleHeaderImage src={headerImage} alt="Erotic Audio Stories by Audiodesires"/>
      <FirstText>
        Self-care is essential - and so many of us just gloss over it. While going out for a walk, practicing yoga, or indulging in a sweet treat are great ways to spoil yourself, really exploring yourself as a sexual being who deserves to feel pleasure is something not many of us make time for. With Audiodesires audiobooks, it’s easy. You can carve out 20-55 minutes of sensual self-care time with one of our relaxing audiobook stories. 
        <br/><br/>
        As a platform, Audiodesires is dedicated to creating empowering, relatable content for both women and couples to enjoy (and indulge in) when it’s time for that romantic self-care. This year we have some new exciting things on the horizon. We can offer you a whole new way to treat yourself (and each other). 
        <br/><br/>
        Let’s dive into the top 10 erotic audiobook stories you absolutely need to listen to this year. These rankings are created based on ratings they’ve been given by listeners just like you. 
      </FirstText>
      {Stories.sort((a,b) =>  b.rank - a.rank ).map((story) => (
        <ListItemWrapper key={story.rank}>
          
          <ItemImageWrapper>
            <Link to={`/story/${story.en.slug}`}>
              <GatsbyImage
                image={story.localImage.childImageSharp.gatsbyImageData}
                alt={`${story.en.title} - Erotic Audio Story by Audiodesires`} />
            </Link>
          </ItemImageWrapper>
          <ItemTextColumn>
            <ItemHeadline><EroticAudio>{story.subtitle}</EroticAudio>{story.rank}. {story.en.title}</ItemHeadline>
            <ItemMetaInfo>Narrator: <StyledLink to={`/voice/${story[lang].voices[0].name.toLowerCase()}`}>{story[lang].voices[0].name}</StyledLink></ItemMetaInfo>
            <ItemText dangerouslySetInnerHTML={{ __html: content[story.en.slug].text}}/>
            <ItemCta>Listen to <StyledLink to={`/story/${story.en.slug}`}>{story.en.title}</StyledLink>{} now.</ItemCta>
          </ItemTextColumn>
        </ListItemWrapper>
      
      ))}
      <HeaderTitle center >Frequently Asked Questions (FAQ) about Erotic Audiobook Stories</HeaderTitle>
      {FAQContent.mainEntity.map((edge,index) =>(
        <ListItemWrapper key={index}>
          <ItemTextColumn>
          <FAQItemHeadline>{edge.name}</FAQItemHeadline>
          <ItemText dangerouslySetInnerHTML={{ __html: edge.acceptedAnswer.text}}/>
          </ItemTextColumn>
        </ListItemWrapper>
      ))}
    </ContentPaddingWrapper>
  </ArticleWrapper>
</>;
}

export default EroticAudioBookStories
export const query = graphql`{
  allStory(
    filter: {en: {slug: {in: ["seduced-by-paris", "mountaintop-adventure", "edge-of-silk", "wedding-night", "sensuous-valentine", "stranger-in-streets", "alone-in-the-office", "muse", "ranch-hand", "nightclub-heat"]}}}
  ) {
    nodes {
      ...seoBlogPostStoryInfo 
    } 
  }
}
`
